<template>
    <div class="white black--text d-flex wrapper h100 vh100 pa-50 center">
      <div class="tos">
        <Privacy />
      </div>
    </div>
  </template>
  
  <script>
  import TopToolbar from "@/components/Toolbars/TopToolbar.vue";
  import Privacy from "@/components/Auth/Register/Addendum.vue";
  export default {
    components: {
      TopToolbar,
      Privacy,
    },
  };
  </script>
  
  <style scoped>
  .tos {
    max-width: 976px;
    width: 100%;
    box-sizing: border-box;
    padding: 70px;
    background-color: white;
  }
  
  .wrapper {
    background-color: #525659;
  }
  

  
  @media only screen and (max-width: 576px) {
    .wrapper {
      padding: 0px;
    }
  
    .tos {
      padding: 10px;
    }
  }
  </style>